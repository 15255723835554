<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="flex flex-wrap justify-between items-center w-80 center pv4-l pv3 mini-spacing">
                <div class="w-100">
                    <div class="w-100 center">
                        <div class="pb4">
                            <p class="f3 b">
                                Trial Balance
                                <strong
                                    ><font-awesome-icon
                                        icon="fa-solid fa-question-circle fa-2xs"
                                        class="text-muted clickModalIcon"
                                        @click="clickModalTrialBalance = true"
                                        style="cursor: pointer; padding-bottom: 2px"
                                /></strong>
                            </p>
                            <div class="dashboard-inquiry-modal" v-if="clickModalTrialBalance">
                                You can generate a report for the performance of your business on a daily, weekly or monthly
                                basis. Click <a href="#" target="_blank" style="color: blue">here</a> to learn more.
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center justify-between pb4">
                        <div class="flex gap16 flex-column w-40">
                            <div>Date</div>
                            <div class="w-100"><input class="trialDate w-100" type="date" /></div>
                        </div>
                        <div><button>Update</button></div>
                    </div>
                    <div>
                        <div class="flex justify-between pad1016 tbSub">
                            <div class="w-30 tl b">Accounts</div>
                            <div class="w-30 tr f500">Debits</div>
                            <div class="w-30 tr f500">Credits</div>
                        </div>
                        <div class="pv2">
                            <div class="flex justify-between pad1016 trSub2">
                                <div class="w-30 tl b">Assets</div>
                                <div class="w-30 tr"></div>
                                <div class="w-30 tr"></div>
                            </div>
                            <div class="flex justify-between pad1016 tbSub">
                                <div class="w-30 tl b">Total Assets</div>
                                <div class="w-30 tr f500">0.00</div>
                                <div class="w-30 tr f500">0.00</div>
                            </div>
                        </div>
                        <div class="pv2">
                            <div class="flex justify-between pad1016 trSub2">
                                <div class="w-30 tl b">Liability</div>
                                <div class="w-30 tr"></div>
                                <div class="w-30 tr"></div>
                            </div>
                            <div class="flex justify-between pad1016 tbSub">
                                <router-link :to="{name: 'TrialAccount'}" class="w-30 tl b flex items-center" style="gap: 2px; color: #132c8c">
                                    <span>Bank Loans</span><span><img :src="require('@/assets/images/tb-arrow.svg')" /></span>
                                </router-link>
                                <div class="w-30 tr f500">0.00</div>
                                <div class="w-30 tr f500">0.00</div>
                            </div>
                            <div class="flex justify-between pad1016 tbSub">
                                <div class="w-30 tl b">Total Liability</div>
                                <div class="w-30 tr f500">0.00</div>
                                <div class="w-30 tr f500">0.00</div>
                            </div>
                        </div>
                        <div class="pv2">
                            <div class="flex justify-between pad1016 trSub2">
                                <div class="w-30 tl b">Income</div>
                                <div class="w-30 tr"></div>
                                <div class="w-30 tr"></div>
                            </div>
                            <div class="flex justify-between pad1016 tbSub">
                                <div class="w-30 tl b flex items-center" style="gap: 2px; color: #132c8c">
                                    <span>Sales</span><span><img :src="require('@/assets/images/tb-arrow.svg')" /></span>
                                </div>
                                <div class="w-30 tr f500">0.00</div>
                                <div class="w-30 tr f500">0.00</div>
                            </div>
                            <div class="flex justify-between pad1016 tbSub">
                                <div class="w-30 tl b">Total Income</div>
                                <div class="w-30 tr f500">0.00</div>
                                <div class="w-30 tr f500">0.00</div>
                            </div>
                        </div>
                        <div class="pv2">
                            <div class="flex justify-between pad1016 trSub2">
                                <div class="w-30 tl b">Expenses</div>
                                <div class="w-30 tr"></div>
                                <div class="w-30 tr"></div>
                            </div>
                            <div class="flex justify-between pad1016 tbSub">
                                <div class="w-30 tl b">Total Expenses</div>
                                <div class="w-30 tr f500">0.00</div>
                                <div class="w-30 tr f500">0.00</div>
                            </div>
                        </div>
                        <div class="pv2">
                            <div class="flex justify-between pad1016 trSub2">
                                <div class="w-30 tl b">Equity</div>
                                <div class="w-30 tr"></div>
                                <div class="w-30 tr"></div>
                            </div>
                            <div class="flex justify-between pad1016 tbSub">
                                <div class="w-30 tl b flex items-center" style="gap: 2px; color: #132c8c">
                                    <span>Drawings</span><span><img :src="require('@/assets/images/tb-arrow.svg')" /></span>
                                </div>
                                <div class="w-30 tr f500">0.00</div>
                                <div class="w-30 tr f500">0.00</div>
                            </div>
                            <div class="flex justify-between pad1016 tbSub">
                                <div class="w-30 tl b">Total Equity</div>
                                <div class="w-30 tr f500">0.00</div>
                                <div class="w-30 tr f500">0.00</div>
                            </div>
                        </div>
                        <div class="flex justify-between pad1016 tbSub">
                            <div class="w-30 tl b">Total of all Account</div>
                            <div class="w-30 tr b">N10,000</div>
                            <div class="w-30 tr b">N5,000</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { ref, onMounted } from 'vue'
import AppWrapper from '@/layout/AppWrapper'

export default {
    name: 'TrialBalance',
    components: { AppWrapper },
    setup() {
        onMounted(() => {
            window.addEventListener('click', (event) => {
                const modalIcon = document.querySelector('.clickModalIcon')

                if (
                    !(event.target.closest('.clickModalIcon') || event.target === modalIcon) &&
                    clickModalTrialBalance.value === true
                ) {
                    closeModal()
                }
            })
        })

        const clickModalTrialBalance = ref(false)
        const closeModal = () => {
            clickModalTrialBalance.value = false
        }
        return {
            clickModalTrialBalance,
            closeModal,
        }
    },
}
</script>
<style>
.trialHeader {
    font-size: 28px;
    font-weight: 600;
}
.gap16 {
    gap: 16px;
}
.trialDate {
    padding: 14px 12px 11px 12px;
    border-radius: 4px;
    border: 1px solid #e3e8ee;
}
.pad1016 {
    padding: 10px 16px;
}
.tbSub {
    color: #1a2035;
    font-size: 14px;
}
.trSub2 {
    background: rgba(85, 89, 110, 0.06);
    color: #132c8c;
    font-size: 16px;
}
.f500 {
    font-weight: 500;
}
</style>
